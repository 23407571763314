
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '@/store'
import NProgress from 'nprogress'

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写VueRouter.prototype身上的push方法了
VueRouter.prototype.push = function(location, resolve, reject) {
  if (resolve && reject) {
   
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写VueRouter.prototype身上replace的方法了
VueRouter.prototype.replace = function(location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    name: "login",
    meta:{
      title:'Login - Cargosoon'
    },
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/signUp",
    name: "signUp",
    meta:{
      title:'SignUp - Cargosoon'
    },
    component: () => import("../views/login/SignUp.vue"),
  },
  {
    path: "/signUpGoogle",
    name: "signUpGoogle",
    meta:{
      title:'SignUp With Google - Cargosoon'
    },
    component: () => import("../views/login/SignUpGoogle.vue"),
  },
  {
    path: "/verifyEmail",
    name: "verifyEmail",
    meta:{
      title:'Verify Email - Cargosoon'
    },
    component: () => import("../views/login/VerifyEmail.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    meta:{
      title:'Forgot Password - Cargosoon'
    },
    component: () => import("../views/login/ForgotPassword.vue"),
  },
  {
    path: "/sendToEmail",
    name: "sendToEmail",
    meta:{
      title:'Send to Email - Cargosoon'
    },
    component: () => import("../views/login/SendToEmail.vue"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    meta:{
      title:'Reset Password - Cargosoon'
    },
    component: () => import("../views/login/ResetPassword.vue"),
  },
  {
    path: "/loginCode",
    name: "loginCode",
    meta:{
      title:'Cargosoon'
    },
    component: () => import("../views/login/loginCode.vue"),
  },
  {
    path: "/gpt",
    name: "gpt",
    meta:{
      title:'Cargosoon'
    },
    component: () => import("../views/gpt/index.vue"),
  },
  {
    path: "/codropshipping",
    name: "codropshipping",
    meta:{
      title:'Sourcing Dropshipping - Cargosoon'
    },
    component: () => import("../views/account/Dropshipping.vue"),
  },
  {
    path: "/",
    component: HomeView,
    redirect: "/main/dashboard",
    meta:{
      title:'Cargosoon',
    },
    children:[
      {
        path: "/main/issus",
        name: "feedback",
        meta:{
          title:'feedback - Cargosoon'
        },
        component: () => import("../views/issus/index.vue"),
      },
      {
        path: "/main/dashboards",
        name: "dashboards",
        meta:{
          title:'Dashboard - Cargosoon'
        },
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/main/dashboard",
        name: "dashboard",
        meta:{
          title:'Dashboard - Cargosoon'
        },
        component: () => import("../views/Dashboards.vue"),
      },
      {
        path: "/main/AIpriceCheck",
        name: "AIpriceCheck",
        meta:{
          title:'AI Price Check - Cargosoon'
        },
        component: () => import("../views/AIprice.vue"),
      },
      // {
      //   path: "/main/message",
      //   name: "message",
      //   meta:{
      //     title:'Message - Cargosoon'
      //   },
      //   component: () => import("../views/Message.vue"),
      // },
      // {
      //   path: "/main/quoteList",
      //   name: "quoteList",
      //   meta:{
      //     title:'Quote List - Cargosoon'
      //   },
      //   component: () => import("../views/quote/QuoteList.vue"),
      // },
      {
        path: "/main/quoteDetail/:id",
        name: "quoteDetail",
        meta:{
          title:'Quote Detail - Cargosoon'
        },
        component: () => import("../views/quote/QuoteDetail.vue"),
      },
      // {
      //   path: "/main/quoteRequest",
      //   name: "quoteRequest",
      //   meta:{
      //     title:'Quote Request - Cargosoon'
      //   },
      //   component: () => import("../views/quote/QuoteRequest.vue"),
      // },
      {
        path: "/main/FCL",
        name: "FCL",
        meta:{
          title:'FCL Price - Cargosoon'
        },
        component: () => import("../views/FCL/priceList.vue"),
      },
      // {
      //   path: "/main/FCLdetail",
      //   name: "FCLdetail",
      //   meta:{
      //     title:'FCL Detail - Cargosoon'
      //   },
      //   component: () => import("../views/FCL/Detail.vue"),
      // },
      {
        path: "/main/FCLbooking",
        name: "FCLbooking",
        meta:{
          title:'FCL Booking - Cargosoon'
        },
        component: () => import("../views/FCL/Booking.vue"),
      },
      {
        path: "/main/FCLlines",
        name: "FCLlines",
        meta:{
          title:'FCL Lines - Cargosoon'
        },
        component: () => import("../views/FCL/Lines.vue"),
      },
      {
        path: "/main/booking",
        name: "shippingList",
        meta:{
          title:'Booking - Cargosoon'
        },
        component: () => import("../views/shipping/ShippingList.vue"),
      },
      {
        path: "/main/parcelPrice",
        name: "parcelPrice",
        meta:{
          title:'Parcel Price - Cargosoon'
        },
        component: () => import("../views/shipping/ParcelPrice.vue"),
      },
      {
        path: "/order/shippingOrder",
        name: "shippingOrder",
        meta:{
          title:'Shipping Order - Cargosoon'
        },
        component: () => import("../views/order/SourcingOrder.vue"),
      },
      {
        path: "/order/booking",
        name: "reqOrder",
        meta:{
          title:'Request Order - Cargosoon'
        },
        component: () => import("../views/order/ReqOrder.vue"),
      },
      {
        path: "/order/forwardingOrder/:id",
        name: "forwardingOrder",
        meta:{
          title:'Forwarding Order - Cargosoon'
        },
        component: () => import("../views/order/ForwardingOrder.vue"),
      },
      // {
      //   path: "/order/placeOrder",
      //   name: "placeOrder",
      //   meta:{
      //     title:'Place Order - Cargosoon'
      //   },
      //   component: () => import("../views/order/PlaceOrder.vue"),
      // },
      {
        path: "/order/tracking",
        name: "tracking",
        meta:{
          title:'Tracking - Cargosoon'
        },
        component: () => import("../views/order/Tracking.vue"),
      },
      {
        path: "/warehouse/InventoryStatistics",
        name: "inventoryStatistics",
        meta:{
          title:'Warehouse - Cargosoon'
        },
        component: () => import("../views/warehouse/InventoryStatistics.vue"),
      },
      {
        path: "/warehouse/CargoManagement",
        name: "CargoManagement",
        meta:{
          title:'Cargo Management - Cargosoon'
        },
        component: () => import("../views/warehouse/CargoManagement.vue"),
      },
      {
        path: "/warehouse/InboundManagement",
        name: "InboundManagement",
        meta:{
          title:'Inbound Management - Cargosoon'
        },
        component: () => import("../views/warehouse/InboundManagement.vue"),
      },
      {
        path: "/warehouse/ApplySpaces",
        name: "ApplySpaces",
        meta:{
          title:'Apply For Storage Space - Cargosoon'
        },
        component: () => import("../views/warehouse/ApplySpaces.vue"),
      },
      {
        path: "/warehouse/ApplySpace",
        name: "ApplySpace",
        meta:{
          title:'Apply For Storage Space - Cargosoon'
        },
        component: () => import("../views/warehouse/ApplySpace.vue"),
      },
      {
        path: "/warehouse/OutboundManagement",
        name: "OutboundManagement",
        meta:{
          title:'Outbound Management - Cargosoon'
        },
        component: () => import("../views/warehouse/OutboundManagement.vue"),
      },
      {
        path: "/warehouse/OutboundDetail",
        name: "OutboundDetail",
        meta:{
          title:'Outbound Management Detail - Cargosoon'
        },
        component: () => import("../views/warehouse/OutboundDetail.vue"),
      },
      {
        path: "/warehouse/StockList",
        name: "StockList",
        meta:{
          title:'Stock List - Cargosoon'
        },
        component: () => import("../views/warehouse/StockList.vue"),
      },
      {
        path: "/warehouse/RentCalculation",
        name: "RentCalculation",
        meta:{
          title:'Warehouse Rent Calculation - Cargosoon'
        },
        component: () => import("../views/warehouse/Rent.vue"),
      },
      // {
      //   path: "/warehouse/sourcing",
      //   name: "warehouse",
      //   meta:{
      //     title:'Warehouse - Cargosoon'
      //   },
      //   component: () => import("../views/warehouse/Warehouse.vue"),
      // },
      // {
      //   path: "/warehouse/logistics",
      //   name: "log_warehouse",
      //   meta:{
      //     title:'Logistics Warehouse - Cargosoon'
      //   },
      //   component: () => import("../views/warehouse/LogisticsWarehouse.vue"),
      // },
      // {
      //   path: "/warehouse/inboundDetails/:id",
      //   name: "inboundDetails",
      //   meta:{
      //     title:'Inbound Details - Cargosoon'
      //   },
      //   component: () => import("../views/warehouse/InboundDetail.vue"),
      // },
      {
        path: "/account/profile",
        name: "profile",
        meta:{
          title:'Profile - Cargosoon'
        },
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "/account/changePassword",
        name: "changePassword",
        meta:{
          title:'Change Password - Cargosoon'
        },
        component: () => import("../views/ChangePassword.vue"),
      },
      {
        path: "/account/shippingAddresses",
        name: "shippingAddresses",
        meta:{
          title:'Shipping Addresses - Cargosoon'
        },
        component: () => import("../views/Address.vue"),
      },
      {
        path: "/account/notice",
        name: "notice",
        meta:{
          title:'Notice - Cargosoon'
        },
        component: () => import("../views/Notice.vue"),
      },
      {
        path: "/account/affiliates",
        name: "affiliates",
        meta:{
          title:'Affiliates - Cargosoon'
        },
        component: () => import("../views/Affiliates.vue"),
      },
      {
        path: "/account/wallet",
        name: "wallet",
        meta:{
          title:'Wallet - Cargosoon'
        },
        component: () => import("../views/Wallet.vue"),
      },
      {
        path: "/account/wallet-topup",
        name: "walletTopup",
        meta:{
          title:'Wallet Topup - Cargosoon'
        },
        component: () => import("../views/WalletTopup.vue"),
      },
      {
        path: "/account/integral",
        name: "integral",
        meta:{
          title:'Integral - Cargosoon'
        },
        component: () => import("../views/account/Integral.vue"),
      },
      {
        path: "/account/coupons",
        name: "coupons",
        meta:{
          title:'Coupons - Cargosoon'
        },
        component: () => import("../views/account/Coupons.vue"),
      },
      {
        path: "/account/MemberReview",
        name: "memberReview",
        meta:{
          title:'Member Review - Cargosoon'
        },
        component: () => import("../views/account/MemberReview.vue"),
      },
      {
        path: "/account/Member",
        name: "member",
        meta:{
          title:'Member - Cargosoon'
        },
        component: () => import("../views/account/Member.vue"),
      },
      {
        path: "/account/Document",
        name: "document",
        meta:{
          title:'Document - Cargosoon'
        },
        component: () => import("../views/account/Document.vue"),
      },
      {
        path: "/account/Bill",
        name: "bill",
        meta:{
          title:'Bill - Cargosoon'
        },
        component: () => import("../views/account/Bill.vue"),
      },
      {
        path: "/account/Shopify",
        name: "shopify",
        meta:{
          title:'Shopify - Cargosoon'
        },
        component: () => import("../views/account/Shopify.vue"),
      },
      {
        path: "/dropshipping",
        name: "dropshipping",
        meta:{
          title:'Sourcing Dropshipping - Cargosoon'
        },
        component: () => import("../views/account/Dropshipping.vue"),
      },
      {
        path: "/account/ProductList",
        name: "productList",
        meta:{
          title:'Product List - Cargosoon'
        },
        component: () => import("../views/account/Product.vue"),
      },
      {
        path: "/account/cart",
        name: "cart",
        meta:{
          title:'My Cart - Cargosoon'
        },
        component: () => import("../views/account/Cart.vue"),
      },
      {
        path: "/account/PaymentResult",
        name: "paymentResult",
        meta:{
          title:'Payment Result - Cargosoon'
        },
        component: () => import("../views/order/PaymentSuccess.vue"),
      },
      {
        path: "/account/TopUpResult",
        name: "TopUpResult",
        meta:{
          title:'Top Up Result - Cargosoon'
        },
        component: () => import("../views/account/TopUpResult.vue"),
      },
      {
        path: "/account/storeOrder",
        name: "storeOrder",
        meta:{
          title:'Store Order - Cargosoon'
        },
        component: () => import("../views/account/StoreOrder.vue"),
      },
      {
        path: "/main/consolidation",
        name: "consolidation",
        meta:{
          title:'Cargo Consolidation - Cargosoon'
        },
        component: () => import("../views/consolidation/Index.vue"),
      },
      {
        path: "/mxshipping",
        name: "mxshipping",
        meta:{
          title:'China-Mexico Shipping Service - Cargosoon'
        },
        component: () => import("../views/mexico/index.vue"),
      },
      {
        path: "/cashipping",
        name: "cashipping",
        meta:{
          title:'China-Canada Shipping Service - Cargosoon'
        },
        component: () => import("../views/canada/index.vue"),
      },
      {
        path: "/eushipping",
        name: "eushipping",
        meta:{
          title:'China-European Countries Shipping Service - Cargosoon'
        },
        component: () => import("../views/european/index.vue"),
      },
      {
        path: "/ccpshipping",
        name: "ccpshipping",
        meta:{
          title:'Colombia, Chile, Peru Shipping Service - Cargosoon'
        },
        component: () => import("../views/ccpShipping/index.vue"),
      },
      {
        path: "/meshipping",
        name: "meshipping",
        meta:{
          title:'Middle East Shipping Service - Cargosoon'
        },
        component: () => import("../views/meShipping/index.vue"),
      },
      {
        path: "/ugshipping",
        name: "ugshipping",
        meta:{
          title:'United Kingdom, Germany Shipping Service - Cargosoon'
        },
        component: () => import("../views/ugShipping/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});

let countriesList = [
  ['MX'],
  ['CA'],
  ['GB','DE'],
  ['CO','CL','PE'],
  ['FR','IT','ES','AT','BE','HU','DK','FI','GR','IE','LU','NL','PL','PT','SE','SK','SI','EE','LT','HR','LV','BG','CZ'],
  ['IQ','EG','KW','SA','JO','TR','IL','SA','LB','OM','BH']
]
router.beforeEach((to,from,next)=>{
  if(to.query.ref){
    localStorage.setItem("invitation_code", to.query.ref)
  }
  let token = store.state.user.token
  if(token){
    if(to.query.codes){
      let codes = JSON.parse(to.query.codes)
      for(let n=0;n<countriesList.length;n++){
        for(let m=0;m<countriesList[n].length;m++){
          if(codes.code_two == countriesList[n][m]){
            if(n == 0){
              next({path:'/mxshipping'})
            }else if(n == 1){
              next({path:'/cashipping'})
            }else if(n == 2){
              next({path:'/ugshipping'})
            }else if(n == 3){
              next({path:'/ccpshipping'})
            }else if(n == 4){
              next({path:'/eushipping'})
            }else if(n == 5){
              next({path:'/meshipping'})
            }
          }else{
            next()
          }
        }
      }
    }else{
      next()
    }
  }else{

    // if(to.path.indexOf('/account')!=-1||to.path.indexOf('/main')!=-1||to.path.indexOf('/order')!=-1
    // ||to.path.indexOf('/warehouse')!=-1){
    //   next({path:'/login'})
    // }else{
    //   next()
    // }
    if(to.query.codes){
      let codes = JSON.parse(to.query.codes)
      for(let n=0;n<countriesList.length;n++){
        for(let m=0;m<countriesList[n].length;m++){
          if(codes.code_two == countriesList[n][m]){
            if(n == 0){
              next({path:'/mxshipping'})
            }else if(n == 1){
              next({path:'/cashipping'})
            }else if(n == 2){
              next({path:'/ugshipping'})
            }else if(n == 3){
              next({path:'/ccpshipping'})
            }else if(n == 4){
              next({path:'/eushipping'})
            }else if(n == 5){
              next({path:'/meshipping'})
            }
          }else{
            next()
          }
        }
      }
    }else{
      if(to.path.indexOf('/account')!=-1||to.path.indexOf('/main')!=-1||to.path.indexOf('/order')!=-1
      ||to.path.indexOf('/warehouse')!=-1){
        // if(to.path == '/main/booking' || to.path == '/main/FCL'){
        if(to.path.indexOf('/main/booking')!=-1 || to.path.indexOf('/main/FCL')!=-1 || to.path.indexOf('/warehouse/InventoryStatistics')!=-1
        || to.path.indexOf('/warehouse/RentCalculation')!=-1 || to.path.indexOf('/main/dashboard')!=-1 ||to.path.indexOf('/main/issus')!=-1){
          next()
        }else{
          next({path:'/login'})
        }
      }else{
        next()
      }
    }
  }
});
//   router.beforeEach((to,from,next)=>{
// const key=this.$route.query.key
// if(to.path=='/resetPassword'){
//     await store.dispatch('userLogout');
// }
    
//   });

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
 
router.afterEach(() => {
  NProgress.done()
})
export default router;
